import React, { useContext, useEffect } from "react"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Layout from "../components/wrappers/layout";
import SiteHeaderAlt from "../components/wrappers/site-header-alt";
import SEO from "../components/seo";
import Header from '../components/header';
import Faq from '../components/faq';
import CardArticle from '../components/card-article';
import { graphql } from 'gatsby';
import { isMobile, stripString } from "../utils";
import Markdown from "markdown-to-jsx"
import Cta from "../components/cta";
import Logos from "../components/logos";
import TabbedServices from "../components/tabbed-services";
import Link from "../components/link";
import Testimonial from "../components/testimonial";
import Carousel from "../components/wrappers/carousel";
import CaseListCarousel from "../components/case-list-carousel";
import CaseListCarouselMobile from "../components/case-list-carousel-mobile";
import Media from "../components/media";
import CaseList from "../components/case-list";
import classNames from "classnames";
import { CustomHr } from "../components/CustomHr";
import TriangleBubbles from "../components/hashTagsTriangles";

const ServiceDetailDesign = props => {
  const { footers, general, headers, serviceDesign, servicesPage, theme } = props.data.strapi;
  const { serviceDetailFooterBgColorDarkMode, serviceDetailFooterBgColorLightMode, generalBackgroundColorDarkMode, generalBackgroundColorLightMode, clientLogosColorDarkMode, clientLogosColorLightMode  , fontColorDarkMode, fontColorLightMode, hashtagsBackgroundColor, hashtagsBackgroundColorDarkMode, pageTransitionBgColorLightMode, pageTransitionBgColorDarkMode } = general; 
  const language = props.pageContext.language;
  
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  const openingHours = (language ==="en") && servicesPage?.openingHours || ""; 
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon';
  const breakpoints = useBreakpoint();
  const primaryBtn = general?.primaryBtn;
  const {darkTheme, hero, hashtagsSection, articles, services, services2, logos, cta1, cta2, stats, testimonials, collage1, aboutUs, faqs, bodyImages, copyright, seo, casesHeading, casesSlider, author, serviceEmail, service_design, mobileHeroBackground} = serviceDesign;     
  const pageRedirectInfo = service_design; 
  const redirectTo = pageRedirectInfo?.language && pageRedirectInfo?.slug ? `/${pageRedirectInfo?.language}/services/${pageRedirectInfo?.slug}` : ""; 

  // getting  page first H1 to set as meta title and description if not set in strapi .
  let pageFirstHeading = hero.header && (hero.header.supheading + " "+ hero.header.heading);

  const seoTitle = seo && seo.metaData.metaTitle || pageFirstHeading|| "service detail design ";
  const seoDescription = seo && seo.metaData.metaDescription || pageFirstHeading || "service detail design";
  const seoKeywords = seo && seo.metaData?.keywords;
  const isSubOpen = state.siteSubNav;
  const [anchorLinks, setAnchorLinks] = React.useState([]);
  const greySectionLightModeBgColor = general?.greySectionLightModeBgColor; 
  const greySectionDarkModeBgColor = general?.greySectionDarkModeBgColor; 
  const greySectionBgColor = darkMode ? greySectionDarkModeBgColor : greySectionLightModeBgColor; 
  const switchToDarkMode = theme.switchToDarkMode;
  const imageBorderRadiusForServices = general?.imageBorderRadiusForServices;
  const servicesfooterColor = darkMode ? serviceDetailFooterBgColorDarkMode : serviceDetailFooterBgColorLightMode
  const sectionBackgroundColor = darkMode ? general?.generalBackgroundColorDarkMode : general?.generalBackgroundColorLightMode; 

  const isMobileView = breakpoints.sm;
  const background = isMobileView && mobileHeroBackground ? mobileHeroBackground :  hero?.background && hero?.background;
  const [backgroundImage, setBackgroundImage] = React.useState("");
  const logosBgColor = darkMode ? clientLogosColorDarkMode : clientLogosColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  const filteredRelatedCards = articles?.relatedCard.filter(relatedCard => !relatedCard?.blog?.inactive || relatedCard?.case_study); 
  const displayedHashtagBgColor = darkMode ? hashtagsBackgroundColorDarkMode : hashtagsBackgroundColor;
  const pageTransitionBgColor = darkMode ? pageTransitionBgColorDarkMode : pageTransitionBgColorLightMode; 
  footer.theme = 'short';
  if (copyright) {
     footer.copyright = copyright;
  }


  const newAnchorLinks = []; 

  useEffect(() => {
    hero?.header?.anchorText && newAnchorLinks.push({header: hero.header.anchorText  , display: true}); 
    services?.header.anchorText && newAnchorLinks.push({header: services.header.anchorText, display: true}); 
    services2?.header.anchorText && newAnchorLinks.push({header: services2.header.anchorText, display: true}); 
    logos?.header?.anchorText && newAnchorLinks.push({header: logos?.header?.anchorText, display: true}); 
    cta1?.text?.anchorText && newAnchorLinks.push({header: cta1.text.anchorText, display: true}); 
    casesSlider?.anchorText && newAnchorLinks.push({header: casesSlider.anchorText , display: true}); 
    bodyImages?.header?.anchorText && newAnchorLinks.push({header: bodyImages.header.anchorText , display: true}); 
    faqs?.header?.anchorText && newAnchorLinks.push({header: faqs?.header?.anchorText , display: true}); 
    aboutUs?.header?.heading.anchorText && newAnchorLinks.push({header: aboutUs.header.anchorText , display: true}); 
    stats?.header?.anchorText && newAnchorLinks.push({header: stats.header.anchorText, display: true}); 
    testimonials?.anchorText && newAnchorLinks.push({header: testimonials.anchorText, display: true}); 
    articles?.anchorText && newAnchorLinks.push({header: articles.anchorText, display: true}); 
    cta2?.anchorText && newAnchorLinks.push({header: cta2.anchorText, display: true}); 
    setAnchorLinks(newAnchorLinks);
    const id = "services"; 
    if (!isSubOpen[id]) {
      dispatch({ 
        type: "TOGGLE_SITE_SUB_NAV" , 
        payload: {
          id: id
        } 
      });
    } 
    setBackgroundImage(background);
  }, []); 

  const imageExts = ['.svg', '.png', '.gif', '.jpg', '.jpeg'];
  const isImage = backgroundImage && imageExts.includes(backgroundImage.ext);
  return (
    <Layout header={header} footer={footer} language={language} serviceEmail={serviceEmail} showWhiteIcon={true} redirectTo={redirectTo} anchorLinks={anchorLinks} isImage={!isImage} servicesfooterColor={servicesfooterColor} isServicesLandingPage={true} logosBgColor={logosBgColor}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="service-detail" 
        title={seoTitle} 
        description={seoDescription} 
        keywords={seoKeywords} 
        darkTheme={darkTheme}  
        darkMode={darkMode}
        websiteBackgroundColor={sectionBackgroundColor}
      />

      {hero && (
        <section className="hero hero--white service-detail-design" style={{backgroundColor: hero.backgroundColor}} id={stripString(hero.header.anchorText ) || ''}>
          <div className="container-lg container align-items-end">
            <div className="hero__background hero__background--shadow">
              {backgroundImage && (
                <div className="hero__background-inner">
                  <Media media={backgroundImage} showVideoAutoWith={true} />
                </div>
              )}
            </div>
            <div className="hero__main">
              <div className="row align-items-end">
                <div className="col-12">
                  <div className="hero__content">
                    <header className="hero__header header">
                      <h1 className="header__heading">
                        <small className="header__preheading">
                          {hero.header.supheading}
                        </small>
                        {hero.header.heading}
                      </h1>
                    </header>
                    
                    <div className="hero__body rich-text">
                      <Markdown options={{
                        overrides: {a: {
                          component: Link
                        },                           
                        hr: { 
                            component: CustomHr,
                            props: {
                              borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                            }
                          }  
                        }
                      }}
                    > 
                       {hero.body}
                    </Markdown>
                    </div>

                    {hero.links && (
                      <div className="hero__buttons buttons">
                        {hero.links.map((link) => {
                          return (
                            <>
                              {link.document && ( 
                                <a href={`${link.document && link.document.url}`} className="button button--white" target="_blank">
                                  <span className="button__text">{link.label}</span>
                                </a>
                              )}
                            </>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <SiteHeaderAlt header={header} serviceEmail={serviceEmail} redirectTo={redirectTo}  anchorLinks={anchorLinks} servicesfooterColor={servicesfooterColor}>

        {services && (
          <TabbedServices 
            component={services} 
            language={language} 
            shouldNotLazyLoad={true} 
            imageBorderRadiusForServices={imageBorderRadiusForServices} 
            sectionBackgroundColor={sectionBackgroundColor}
            websiteFontColor={websiteFontColor}
          />
        )}

        {logos && (
          <Logos component={logos} shouldNotLazyLoad={true} sectionBackgroundColor={sectionBackgroundColor} logosBgColor={logosBgColor} />
        )}

        {cta1 && (
          <Cta 
            component={cta1} 
            className="cta--wide" 
            showNumber={true} 
            showTeaser={true} 
            author={author}
            openingHour={openingHours}  
            anchorId={cta1?.text?.anchorText ? cta1.text.anchorText : ''}
            shouldNotLazyload={true}
            greySectionBgColor={greySectionBgColor}
            fontColorLightMode={websiteFontColor}
          />
        )}

        {services2 && (
          <TabbedServices 
            component={services2} 
            language={language}  
            shouldNotLazyLoad={true}
            imageBorderRadiusForServices={imageBorderRadiusForServices} 
            sectionBackgroundColor={sectionBackgroundColor}
            websiteFontColor={websiteFontColor}
          />
        )}
        {(casesSlider && casesSlider.caseStudies.length > 0) && (
          <section 
            className="section cases" 
            id={stripString(casesHeading?.anchorText) || ''} 
            style={{backgroundColor: sectionBackgroundColor}}
          >
            {casesSlider.heading && (
              <Header 
                heading={casesSlider?.heading} 
              />
            )}
            <div className="container">
                <div className="row">
                  <div className="offset-lg-1 col-lg-10">
                    <CaseList
                      caseStudies={casesSlider.caseStudies} 
                      oddElementMarginTop={true}  
                      showCaseIntroText={true}
                      imageBorderRadiusForServices={imageBorderRadiusForServices}
                      websiteFontColor={websiteFontColor}
                      hashtagsBackgroundColor={displayedHashtagBgColor}
                      pageTransitionBgColor={pageTransitionBgColor}
                    />
                  </div>
                </div>
            </div>
          </section>
        )}

        {bodyImages && (
          <section className="section plain-cards" id={bodyImages?.header?.anchorText ? stripString(bodyImages.header.anchorText) : ''} style={{backgroundColor: sectionBackgroundColor}}>
            {bodyImages.header && (
              <Header heading={bodyImages.header.heading} supheading={bodyImages.header.supheading} />
            )}

            {bodyImages.bodyImages && (
              <div className="container">
                <div className={classNames("row", {"card-articles": isMobileView})}>
                  {bodyImages.bodyImages.map((bodyImage) => {
                    return (
                      <div className={classNames({"col-md-4 col-lg-4" : !isMobileView}, {"card-article col" : isMobileView})}>
                        {bodyImage.media && bodyImage.media.length > 1 ? (
                          <Carousel>
                            {bodyImage.media.map((media, index) => {
                              return <img style={{borderRadius: imageBorderRadiusForServices}} className="plain-cards__image image--fluid" src={media.url} />
                            })}
                          </Carousel>
                        ) : (
                          <img 
                            className="plain-cards__image image--fluid" 
                            src={(bodyImage.media[0].url)} alt={bodyImage.media[0].alternativeText} 
                            style={{borderRadius: imageBorderRadiusForServices}}
                          />
                        )}

                        <div className="plain-cards__body rich-text">
                          <Markdown options={{
                            overrides: {a: {
                              component: Link
                            },                           
                            hr: { 
                                component: CustomHr,
                                props: {
                                  borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                }
                              }  
                            }
                          }}>
                            {bodyImage.richText}
                          </Markdown>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </section>
        )}


        {aboutUs && (
          <section className="section" id={aboutUs.header.anchorText ? stripString(aboutUs.header.anchorText) : ''} style={{backgroundColor: sectionBackgroundColor}}>
            {aboutUs.header && (
              <Header heading={aboutUs.header.heading} supheading={aboutUs.header.supheading} />
            )}
            <div className="container">
              <div className="row">
                <div className="offset-md-2 col-md-7 rich-text">
                  <Markdown options={{
                    overrides: {a: {
                      component: Link
                    },                           
                    hr: { 
                        component: CustomHr,
                        props: {
                          borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                        }
                      }  
                    }
                  }}>{aboutUs.body}</Markdown>
                </div>
              </div>
            </div>
            <div className="collage collage--short">
              <div className="container">
                <div className="row">
                  <div className="offset-md-1 col-md-3 collage-item">
                    {collage1[0] && (
                      <img 
                        src={collage1[0].url} 
                        style={{borderRadius: imageBorderRadiusForServices}}
                        alt={collage1[0].alternativeText}  
                      />
                    )}
                  </div>
                  <div className="offset-md-1 col-md-6 collage-item">
                    {collage1[1] && (
                      <img 
                        src={collage1[1].url} 
                        style={{borderRadius: imageBorderRadiusForServices}}
                        alt={collage1[1].alternativeText}  
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {stats && (
          <section 
            className="section section--grey stats" 
            id={stats?.header?.anchorText ? stripString(stats.header.anchorText) : ''}
            style={{backgroundColor: greySectionBgColor}}
          >
            {stats.header && (
              <Header heading={stats.header.heading} supheading={stats.header.supheading} withFullWidth={true}/>
            )}
            
            {(stats.stat && stats.stat.length > 0) && (
              <div className="container">
                <div className="row">
                  <div className="col-12 stats__list">
                    {stats.stat.map((stat) => {
                      return (
                        <div className="stats__stat">
                          <h2>{stat.value.replace('+', '')}{stat.value.includes('+') && (<span>+</span>)}</h2>
                          <h3 style={{color: websiteFontColor }}>{stat.label}</h3>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
          </section>
        )}
        {(faqs && faqs.faqs.length > 0) && (
          <section
             className="section faqs section--grey" 
             id={faqs?.header?.anchorText ? stripString(faqs.header.anchorText) : ''}
             style={{backgroundColor: greySectionBgColor}}
          >
            {faqs.header && (
              <Header heading={faqs.header.heading} supheading={faqs.header.supheading} />
            )}
            {faqs.faqs && (
              <div className="container">
                <div className="row">
                  {faqs.faqs.map((faq) => {
                    return (
                      <div className="col-tw-12 col-md-9 offset-md-1 col-lg-8 offset-lg-2 rich-text">
                        <Faq heading={faq.heading} shouldNotLazyLoad={true} showWhitePlus={darkTheme || switchToDarkMode || darkMode} fontColorLightMode={fontColorLightMode}>
                          <Markdown options={{
                            overrides: {a: {
                              component: Link
                            },                           
                            hr: { 
                                component: CustomHr,
                                props: {
                                  borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                }
                              }  
                            }
                          }}>
                            {faq.richText}
                          </Markdown>
                        </Faq>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </section>
        )}
        {(testimonials && testimonials?.showTestimonials && testimonials?.testimonials?.length ) ?  (
          <section className="section" id={stripString(testimonials.anchorText)} style={{backgroundColor: sectionBackgroundColor}}>
            <div className="container">
              <Testimonial 
                testimonialBtnText={primaryBtn}
                testimonials={testimonials.testimonials} 
                shouldNotLazyLoad={true} 
                title={testimonials.title}
                imageBorderRadiusForServices={imageBorderRadiusForServices}
                logosBgColor={logosBgColor}
                sectionBackgroundColor={sectionBackgroundColor}
              />
            </div>
          </section>
        ) : (<></>)}

        {(articles && filteredRelatedCards.length > 0 ) && (
          <section className="cards-articles section" id={articles?.anchorText ? stripString(articles.anchorText) : ''} style={{backgroundColor: sectionBackgroundColor}}>
            <Header heading={articles.heading} supheading={articles.sup} withFullWidth={true}/>

            <div className="container">
              <div className="row card-articles">
                {filteredRelatedCards.map((relatedCard, index) => {
                  const article = relatedCard.blog || relatedCard.case_study;

                  return ( 
                    <CardArticle 
                      article={article} 
                      path={relatedCard.blog ? 'blog' : 'case-studies'} 
                      shouldNotLazyLoad={true}
                      imageBorderRadiusForServices={imageBorderRadiusForServices}
                      hashtagsBackgroundColor={displayedHashtagBgColor}
                    />
                  )
                })}
              </div>
            </div>
          </section>
        )}
        {hashtagsSection && (
          <section className="section">
            <TriangleBubbles  
              hashtagsBackgroundColor={displayedHashtagBgColor} 
              borderRadius={imageBorderRadiusForServices}
              hashtagsSection={hashtagsSection}
            />
          </section>
        )}
        {cta2 && (
          <Cta 
            component={cta2} 
            className="cta--wide" 
            showNumber={true} 
            showTeaser={true} 
            author={author} 
            openingHour={openingHours} 
            anchorId={cta2?.anchorText ? cta2.anchorText : ''}
            shouldNotLazyload={true}
            greySectionBgColor={greySectionBgColor}
            fontColorLightMode={websiteFontColor}
          />
        )}
      </SiteHeaderAlt>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ServiceDetailDesign($id: ID!, $language: String!) {
    strapi {
      general {
        primaryBtn
        blogsOverviewHeadline
        hashtagsBackgroundColor
        hashtagsBackgroundColorDarkMode
        imageBorderRadiusForServices
        greySectionLightModeBgColor
        greySectionDarkModeBgColor
        serviceDetailFooterBgColorDarkMode
        serviceDetailFooterBgColorLightMode
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        clientLogosColorDarkMode
        clientLogosColorLightMode
        fontColorDarkMode
        fontColorLightMode
        pageTransitionBgColorLightMode
        pageTransitionBgColorDarkMode
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
      theme {
        switchToDarkMode
      }
      servicesPage {
        openingHours
      }
      serviceDesign(id: $id) {
       darkTheme
       author {
          name
          picture {
            url
          }
          jobTitle
        }
        hashtagsSection {
          heading
          tags {
            tag
          }
        }
        service_design {
          language
          slug
        }
        mobileHeroBackground {
          url
          alternativeText
          mime
          caption
          ext
        }
        hero {
          background {
            url
            alternativeText
            mime
            caption
            ext
          }
          header {
            supheading
            heading
            anchorText
          }
          body
          links {
            label
            path
            document {
              url
            }
          }
        }
       casesSlider {
         heading
         anchorText
         caseStudies {
            ...CardArticleCaseStudy
          }
        }
        casesHeading {
          heading
          supHeading
          anchorText
        }
        services {
          header {
            supheading
            heading
            anchorText
          }
          services {
            heading
            media {
              media {
                url 
              }
            }
            body {
              richText
            }
            link {
              label
              path
            }
          }
        }
        services2 {
          header {
            supheading
            heading
            anchorText
          }
          services {
            heading
            media {
              media {
                url 
              }
            }
            body {
              richText
            }
            link {
              label
              path
            }
          }
        }
        logos {
          header {
            supheading
            heading
            anchorText
          }
          logos {
            url
            alternativeText
            mime
            ext
          }
          affix
        }
        cta1 {
          text {
            heading
            description
          }
          link {
            label
            path
          }
        }

        bodyImages {
          header {
            supheading
            heading
            anchorText
          }
          bodyImages {
            media {
              url
              alternativeText
              mime
              ext
            }
            richText
          }
        }
        faqs {
          header {
            heading
            supheading
            anchorText
          }
          faqs {
            heading
            richText
          }
        }
        aboutUs {
          header {
            supheading
            heading
            anchorText
            logo {
              url
              alternativeText
              mime
              ext
            }
          }
          body
          buttons {
            path
            label
          }
        }
        collage1 {
          url
          alternativeText
          mime
          ext
        }
        stats {
          header {
            supheading
            heading
            anchorText
          }
          stat {
            value
            label
          }
        }
        testimonials {
          title
          showTestimonials
          anchorText
          testimonials {
            media {
              url
              alternativeText
              mime
              ext
            }
            quote
            description
            name
            position
            company
            logo {
              url
              alternativeText
              mime
              ext
            }
          }
        }
        articles {
          sup
          heading
          relatedCard {
            blog {
              ...CardArticleBlog
            }
            case_study {
              ...CardArticleCaseStudy
            }
          }
        }
        cta2 {
          text {
            heading
            description
          }
          link {
            label
            path
          }
          anchorText
        }
        serviceEmail
        seo {
          metaData {
            metaTitle
            metaDescription
            keywords
          }
        }
        copyright
      }
    }
    thumbrow: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 239, height: 239, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
    category: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 655, height: 369, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
    logo: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 155, height: 105, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
  }
`

export default ServiceDetailDesign
